import PropTypes from "prop-types"
import { forwardRef, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useTheme } from "@mui/material/styles"
import {
  ListItemButton,
  ListItemIcon,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material"
import { RootState } from "store"
import { handleClose, handleMenuClick } from "store/drawerReducer"
import { ListAlt } from "@mui/icons-material"
import styled from "@emotion/styled"

const NavItem = ({ item, level }: any) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const customization = useSelector((state: RootState) => state.drawer)
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  const location = useLocation()
  const leftDrawerOpened = useSelector(
    (state: RootState) => state.drawer.opened
  )

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: `#d2112b !important`,
    },
  }))

  let itemTarget = "_self"
  if (item.target) {
    itemTarget = "_blank"
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref as any} {...props} to={item.url} target={itemTarget} />
    )),
    href: "",
    target: null,
  }

  if (item?.external) {
    listItemProps = {
      component: "a" as any,
      href: item.url as any,
      target: itemTarget as any,
    }
  }

  const itemHandler = (id: any) => {
    dispatch(handleMenuClick(id))
    if (matchesSM) dispatch(handleClose())
  }

  const getDrawerIcons = (item: any) => {
    const active_id = customization.isOpen.findIndex(
      (id: any) => id === item?.id
    )

    function icons_check() {
      return active_id >= 0 ? "inactive" : "active"
    }

    const icon_map: Record<string, string> = {
      dashboard: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/dashboard.png`
      ),
      category: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/categories.png`
      ),
      "sub-category": require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/sub-category.png`
      ),
      sizes: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/sizes.png`
      ),
      "pizza-products": require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/pizza.png`
      ),
      bread: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/bread.png`
      ),
      chicken: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/chicken.png`
      ),
      sandwich: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/sandwich.png`
      ),
      wrap: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/wrap.png`
      ),
      "load-potato": require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/load-potato.png`
      ),
      dessert: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/dessert.png`
      ),
      drinks: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/drinks.png`
      ),
      sauce: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/souce.png`
      ),
      roles: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/roles.png`
      ),
      stores: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/stores.png`
      ),
      users: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/users.png`
      ),
      toppings: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/pizza-topping.png`
      ),
      crust: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/crust.png`
      ),
      edge: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/edge.png`
      ),
      banners: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/banners.png`
      ),
      customer: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/customer.png`
      ),
      "sms-management": require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/sms-management.png`
      ),
      olo: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/online-order.png`
      ),
      draft: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/draft.png`
      ),
      awaiting: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/awaiting.png`
      ),
      courier: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/courier.png`
      ),
      "product-group": require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/online-order.png`
      ),
      coupon: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/coupon.png`
      ),
      "job-posting": require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/job-post.png`
      ),
      candidates: require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/candidates.png`
      ),
      "ongoing-orders": require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/ongoing-orders.png`
      ),
      "order-history": require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/order-history.png`
      ),
      "promo-code": require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/promo-code.png`
      ),
      "points": require(
        `../../../../assets/DrawerPNGICON/${icons_check()}/points.png`
      ),
    }

    if (item?.id && icon_map[item?.id]) {
      return (
        <img
          src={icon_map[item?.id]}
          width={20}
          height={20}
          alt={`${item?.id} icon`}
        />
      )
    } else {
      return <ListAlt sx={{ color: active_id >= 0 ? "#fff" : "#d2112b" }} />
    }
  }

  useEffect(() => {
    const currentIndex = document?.location?.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === item?.id)

    if (currentIndex > -1) {
      dispatch(handleMenuClick(item?.id))
    }
    // eslint-disable-next-line
  }, [location.pathname])

  return (
    <BootstrapTooltip
      title={!leftDrawerOpened && item?.title}
      placement="right"
    >
      <ListItemButton
        {...listItemProps}
        disabled={item?.disabled}
        sx={{
          borderRadius: `${customization?.borderRadius}px`,
          mb: 0.5,
          alignItems: "flex-start",
          textTransform: "uppercase",
          py: 0.8,
          pl: leftDrawerOpened
            ? item?.id === "pizza-products" ||
              item?.id === "toppings" ||
              item?.id === "edge" ||
              item?.id === "crust"
              ? `${level * 15}px`
              : `${level * 10}px`
            : 0,
          pr: 0,
        }}
        selected={
          customization?.isOpen?.findIndex((id: any) => id === item?.id) > -1
        }
        className={
          customization?.isOpen.findIndex((id: any) => id === item?.id) > -1
            ? "active-list-item"
            : "list-item"
        }
        onClick={() => itemHandler(item?.id)}
      >
        <ListItemIcon
          sx={{
            my: "auto",
            minWidth: !item?.icon ? 18 : 36,
            mx: !leftDrawerOpened ? "auto" : {},
          }}
        >
          {getDrawerIcons(item)}
        </ListItemIcon>
        {leftDrawerOpened ? (
          <p
            style={{
              marginBottom: 0,
              flex: 1,
              paddingLeft: 10,
            }}
          >
            {item?.title}
          </p>
        ) : null}

        {matchesSM && !leftDrawerOpened ? (
          <p
            style={{
              marginBottom: 0,
              flex: 1,
              paddingLeft: 10,
            }}
          >
            {item?.title}
          </p>
        ) : null}
      </ListItemButton>
    </BootstrapTooltip>
  )
}

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
}

export default NavItem
